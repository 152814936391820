
import {
  createStation,
  editStation,
  getStationList,
  getStationOptions,
  IStation,
  IStationOption,
  removeStation,
  setStationSort,
  importStation,
  checkCookies,
} from "@/service/common";
import { message, Modal, notification } from "ant-design-vue";
import { computed, defineComponent, reactive, Ref, ref } from "vue";
import { BackCookies, CookieItem } from "@/interface";
import { saveAs } from "file-saver";

export interface IFormState {
  name: string;
  code: string | null;
  cookie: string;
  icon: string;
}
import draggable from "vuedraggable";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    draggable,
  },
  setup() {
    const visible = ref(false);
    const showDrawer = () => {
      visible.value = true;
    };

    const closeDrawer = () => {
      formEle.value.resetFields();
      editId = 0;
      visible.value = false;
    };
    let editId = 0;
    const formState = reactive<IFormState>({
      code: null,
      name: "",
      cookie: "",
      icon: "",
    });
    const StationOptions: Ref<IStationOption[]> = ref([]);
    const getOptions = () => {
      getStationOptions().then((res) => {
        if (res.code === 200) {
          StationOptions.value = res.data;
          rushList();
        }
      });
    };
    const canSelectStationOptions = computed(() => {
      return StationOptions.value.map((item) => {
        return {
          ...item,
          disabled: stationList.value.find((v) => v.code === item.code),
        };
      });
    });
    getOptions();
    const stationList = ref<IStation[]>([]);
    const rushList = () => {
      getStationList().then((res) => {
        if (res.code === 200) {
          stationList.value = res.data.map((s) => {
            return {
              ...s,
            };
          });
        }
      });
    };
    const rules = {
      code: [
        {
          required: true,
          message: "请选择站点",
        },
      ],
      name: [
        {
          required: true,
          message: "请输入备注",
        },
      ],
      cookie: [
        {
          required: true,
          message: "请输入cookie",
        },
      ],
    };
    const stationChange = (val: string, option: any) => {
      console.log(val, option);
      const selected = option.option as IStationOption;
      formState.code = selected.code;
      formState.name = selected.name;
      formState.icon = selected.icon || "";
      formState.cookie = "";
    };
    const submitLoading = ref(false);
    const formEle = ref();
    const sumbitHandle = () => {
      formEle.value.validate().then(() => {
        submitLoading.value = true;
        let doFunc;
        if (editId) {
          // 编辑
          doFunc = editStation({
            id: editId,
            code: formState.code || "",
            remark: formState.name,
            cookie: formState.cookie,
            icon: formState.icon,
          });
        } else {
          // 新增
          doFunc = createStation({
            code: formState.code || "",
            remark: formState.name,
            cookie: formState.cookie,
            icon: formState.icon,
          });
        }
        doFunc
          .then((res) => {
            if (res.code === 200) {
              notification.success({
                message: "成功",
              });
              closeDrawer();
              rushList();
            }
          })
          .finally(() => {
            submitLoading.value = false;
          });
      });
    };
    const editStationHandle = (station: IStation) => {
      const option = StationOptions.value.find((v) => v.code === station.code);
      editId = station.id;
      formState.code = station.code;
      formState.name = station.remark;
      formState.cookie = station.cookie;
      formState.icon = station.icon || option?.icon || "";
      showDrawer();
    };
    const delStationHandle = (station: IStation) => {
      Modal.confirm({
        title: "提示",
        content: `确定删除[${station.remark}]?`,
        cancelText: "取消",
        okText: "确定",
        onOk: () => {
          console.log(12312321);
          removeStation(station.id).then((res) => {
            if (res.code === 200) {
              rushList();
            }
          });
        },
      });
    };
    const dragStart = () => {
      console.log("拖拽开始");
    };
    const dragEnd = () => {
      setStationSort(stationList.value.map((s) => s.id));
    };

    const beforeUpload = (file: any) => {
      if (!StationOptions.value.length) {
        return message.error("未获取到支持的站点列表选项，请刷新页面后重试！");
      }
      if (file.type !== "application/json") {
        return message.error("仅支持JSON格式文件");
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        console.log("读取的文件", e);
        if (!e?.target?.result) {
          return message.error("读取文件失败");
        }
        try {
          let json = JSON.parse(e.target.result as string);
          dealWithImportData(json);
        } catch (e: any) {
          return message.error("解析文件失败:" + e?.message);
        }
      };
      reader.readAsText(file); //读取文件的内容

      return false;
    };
    const dealWithImportData = (data: BackCookies[]) => {
      const importResult: IFormState[] = [];
      data.forEach((ck) => {
        console.log(ck);
        const station = StationOptions.value.find((s) => s.host === ck.host);
        if (station) {
          // 支持当前站点
          importResult.push({
            code: station.code,
            name: station.name,
            icon: station.icon || "",
            cookie: dealWithCookies(ck.cookies),
          });
        }
      });
      if (importResult.length) {
        importResultData.value = importResult;
        importResultVisible.value = true;
      } else {
        return message.error("无成功导入的站点");
      }
    };
    const dealWithCookies = (cookie: string | CookieItem[]) => {
      if (typeof cookie === "string") {
        return cookie;
      } else {
        return cookie.map((ck) => `${ck.name}=${ck.value}`).join("; ");
      }
    };

    const importResultVisible = ref(false);
    const importLoading = ref(false);
    const importResultData = ref<IFormState[]>([]);
    const handleImport = () => {
      importLoading.value = true;
      importStation(
        importResultData.value.map((i) => {
          return {
            code: i.code || "",
            remark: i.name,
            cookie: i.cookie,
            icon: i.icon,
          };
        })
      )
        .then((res) => {
          rushList();
        })
        .finally(() => closeImportModal());
    };
    const closeImportModal = () => {
      importResultVisible.value = false;
      importLoading.value = false;
      importResultData.value = [];
    };
    const exportHandle = () => {
      const exportData = stationList.value.map((station) => {
        const option = StationOptions.value.find(
          (option) => option.code === station.code
        );
        return {
          host: option?.host,
          cookies: station.cookie,
        };
      });
      const blob = new Blob([JSON.stringify(exportData)], {
        type: "application/json;charset=utf-8",
      });
      saveAs(
        blob,
        `PtMP-Cookie-Backup-${dayjs().format("YYYY-MM-DD_HH-mm-ss")}.json`
      );
    };

    const checkCookieLoading = ref(false);
    const checkCookiesHandle = () => {
      formEle.value.validate().then(() => {
        checkCookieLoading.value = true;
        const { code, cookie } = formState;
        const option = StationOptions.value.find((s) => s.code === code);
        if (!option?.url)
          return message.error("出问题了，未找到对应站点配置项");
        checkCookies(option.url, cookie)
          .then((res) => {
            console.log(res);
            if (res.code === 200) {
              if (res.data) {
                message.success(`校验成功，用户名${res.data}`, 3);
              } else {
                message.error(
                  "cookie测试未通过，可重试2-3次，同样失败请检查cookie的有效性",
                  3
                );
              }
            } else if (res.message.includes("timeout of")) {
              message.error("请求超时，可能你的网络访问当前站点很慢呦", 3);
            } else {
              message.error(
                "cookie测试未通过，可重试2-3次，同样失败请检查cookie的有效性",
                3
              );
            }
          })
          .finally(() => (checkCookieLoading.value = false));
      });
    };
    return {
      dragStart,
      dragEnd,
      visible,
      showDrawer,
      closeDrawer,
      formState,
      stationList,
      canSelectStationOptions,
      StationOptions,
      formEle,
      sumbitHandle,
      rules,
      stationChange,
      submitLoading,
      delStationHandle,
      editStationHandle,
      beforeUpload,
      importResultVisible,
      importLoading,
      handleImport,
      importResultData,
      exportHandle,
      checkCookiesHandle,
      checkCookieLoading,
    };
  },
});
